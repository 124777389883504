import React from "react";
import SmallBanner from "@components/common/SmallBanner";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import ShoppingCart from "@components/sections/ShoppingCart";

const Cesta = () => {
    const storeEnabled = process.env.GATSBY_STORE_ENABLED === "true";

    return (
        <>
            <Seo title="Cesta" />
            <Layout>
                <SmallBanner title="Cesta" filename="bgs/bgCart" />

                {storeEnabled ? (
                    <div className="container lg:px-0 py-20">
                        <Title className="text-left">Resumen del pedido</Title>
                        <Text className="text-justify">
                            A continuación te mostramos un listado de los productos que quieres
                            comprar, así como los gastos de envío, en caso que el producto sea
                            físico. Recuerda que para realizar el pedido has de rellenar todos los
                            datos que hay en el formulario de <i>Datos del envío</i>.
                        </Text>

                        <ShoppingCart className="mt-10" />
                    </div>
                ) : (
                    <div className="container py-20">
                        Proceso de compra deshabilitado temporalmente. En breve volverá a estar
                        disponible.
                    </div>
                )}
            </Layout>
        </>
    );
};
export default Cesta;
