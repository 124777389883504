import React from "react";
import { PayPalButton } from "react-paypal-button-v2";

const PaypalPayment = ({
    items,
    subtotal,
    style,
    currency,
    shipping,
    total,
    discount,
    functions,
    className,
}) => {
    const { onError, onCancel, onSuccess, setProcessing, onStart } = functions;

    // console.log(subtotal, shipping, discount, total);

    // Preparamos array para pasar a Paypal
    const paypalItems =
        items &&
        items.length > 0 &&
        items.map(i => {
            return {
                name: i.name,
                description: i.name,
                sku: i.sku,
                unit_amount: {
                    currency_code: currency,
                    value: i.price,
                },
                quantity: i.quantity,
            };
        });

    // const discount = items && items.length > 0 && items.find(x => x.type === "discount");

    const _createOrder = (data, actions) => {
        setProcessing(true);
        onStart();

        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: currency,
                        value: total,
                        breakdown: {
                            item_total: {
                                currency_code: currency,
                                value: subtotal,
                            },
                            shipping: {
                                currency_code: currency,
                                value: shipping,
                            },
                            ...(discount && {
                                discount: {
                                    currency_code: currency,
                                    value: Number(
                                        discount.price || discount.percent
                                            ? discount.percent * subtotal
                                            : 0 || 0,
                                    ).toFixed(2),
                                    name: discount.title,
                                },
                            }),
                        },
                    },
                    description: "Compra página web iOpos",
                    items: paypalItems,
                },
            ],
        });
    };

    const _onCancel = () => {
        setProcessing(false);
        onCancel();
    };

    const _onError = e => {
        setProcessing(false);
        onError(e);
    };

    const _onSuccess = e => {
        setProcessing(false);
        onSuccess(e);
    };

    const _onApprove = (data, actions) => {
        return actions.order.capture().then(details => {
            if (details.status === "COMPLETED") {
                _onSuccess(details);
                console.log(data.orderID);
            } else {
                _onError(details);
            }

            setProcessing(false);
        });
    };

    return (
        <>
            {items && items.length > 0 ? (
                <PayPalButton
                    className={`${className}`}
                    debug={true}
                    style={style}
                    amount={total}
                    createOrder={_createOrder}
                    onApprove={_onApprove}
                    onCancel={_onCancel}
                    onError={_onError}
                    onSuccess={_onSuccess}
                    options={{
                        currency,
                        clientId: process.env.GATSBY_PAYPAL_CLIENT_ID,
                        disableFunding: "card,credit,sofort",
                        enableFunding: "paylater",
                    }}
                />
            ) : (
                "-"
            )}
        </>
    );
};

export default PaypalPayment;
