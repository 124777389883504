import React, { useContext, useState, useRef, useEffect } from "react";
import "@styles/ShoppingCart.css";
import dayjs from "dayjs";
import { CartContext } from "@components/contexts/CartContext";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiOutlineLoading } from "react-icons/ai";
import { MdRemoveShoppingCart, MdPayment } from "react-icons/md";
import firebase from "gatsby-plugin-firebase";
import CustomAlert from "../common/CustomAlert";
import PaypalPayment from "../common/PaypalPayment";
import { BlockUIContext } from "@components/contexts/BlockUIContext";
import axios from "axios";
import useSound from "use-sound";
import purchaseErrorSfx from "@static/sounds/purchaseError.mp3";
import purchaseSuccessSfx from "@static/sounds/purchaseSuccess.mp3";
import addToBasketSfx from "@static/sounds/addProduct.mp3";
import removeFromBasketSfx from "@static/sounds/removeProduct.mp3";
import useLocalStorage from "@components/hooks/useLocalStorage";
import useSWR from "swr";
import { trackFbPurchase, trackFbInitiateCheckout, trackFbAddToCart } from "@base/utils/fb";
import { toast } from "react-toastify";
import useAuth from "@components/hooks/useAuth";

const ShoppingCart = props => {
    const { state, isAuthenticated } = useAuth();
    const [userContactData, setUserContactData] = useState(null);

    // Capturamos datos del usuario
    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                try {
                    const json = await axios.get("/.netlify/functions/user-contactdata", {
                        ...(state?.jwt && {
                            headers: {
                                Authorization: "Bearer " + state?.jwt,
                            },
                        }),
                    });

                    json?.data?.success &&
                        json?.data?.contact_data &&
                        setUserContactData(json?.data?.contact_data);
                } catch (err) {
                    console.log(err?.message);
                }
            })();
        }
    }, [isAuthenticated, state]);

    // Obtenemos los productos disopnibles
    const { data: result } = useSWR("/.netlify/functions/products", axios.get);
    const [, setProductsState] = useState([]);
    const [sendableUnits, setSendableUnits] = useState(0);
    const [shippingTotal, setShippingTotal] = useState(1);
    const [discountLoading, setDiscountLoading] = useState(false);

    const [discountCode, setDiscountCode] = useState("");
    const [discount, setDiscount] = useState(null);

    const [readyToPayment, setReadyToPayment] = useState(false);
    const [executedPayment, setExecutedPayment] = useState(null);

    // Cargamos y preparamos los productos disponibles
    useEffect(() => {
        if (result?.data?.products) {
            let products = result?.data?.products.filter(x => x.enabled && !x.hidden);

            products = products.map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            });

            products = products.sort((a, b) => {
                return b.created_at - a.created_at;
            });

            setProductsState(products);
        }
    }, [result]);

    const [playPurchaseError] = useSound(purchaseErrorSfx, { volume: 0.05 });
    const [playPurchaseSuccess] = useSound(purchaseSuccessSfx, {
        volume: 0.05,
    });

    const [playAddToBasket] = useSound(addToBasketSfx, { volume: 0.05 });
    const [playRemoveFromBasket] = useSound(removeFromBasketSfx, {
        volume: 0.05,
    });

    const { setBlocking } = useContext(BlockUIContext);
    const { cartItems, total, checkout, clearCart, handleCheckout } = useContext(CartContext);

    useEffect(() => {
        (async () => {
            await trackFbAddToCart({
                currency: "EUR",
                value: total,
            });
        })();
    }, [total]);

    // Cada vez que se modifica el carrito contamos los ítems de los libros
    useEffect(() => {
        let units = 0;
        for (const item of cartItems) {
            if (item.type === "book") {
                units += item.quantity * item.items || 1;
            }
        }

        setSendableUnits(units);

        if (units === 1 || units === 2) {
            setShippingTotal(Number(4.49).toFixed(2));
        } else if (units >= 3 && units <= 5) {
            setShippingTotal(Number(units * 3.0).toFixed(2));
        } else if (units >= 6) {
            setShippingTotal(Number(units * 2.75).toFixed(2));
        } else {
            setShippingTotal(Number(0).toFixed(2));
        }
    }, [cartItems]);

    /** Datos del formulario */
    const [userName, setUserName] = useLocalStorage("inputShoppingCartUserName", "");

    const [userLastName, setUserLastName] = useLocalStorage("inputShoppingCartUserLastName", "");

    const [userEmail, setUserEmail] = useLocalStorage("inputShoppingCartUserEmail", "");

    const [userEmailRepeat, setUserEmailRepeat] = useLocalStorage(
        "inputShoppingCartUserEmailRepeat",
        "",
    );

    const [userPhone, setUserPhone] = useLocalStorage("inputShoppingCartUserPhone", "");

    const [userAddress, setUserAddress] = useLocalStorage("inputShoppingCartUserAddress", "");

    const [userPostalCode, setUserPostalCode] = useLocalStorage(
        "inputShoppingCartUserPostalCode",
        "",
    );

    const [userProvince, setUserProvince] = useLocalStorage("inputShoppingCartUserProvince", "");

    const [userTown, setUserTown] = useLocalStorage("inputShoppingCartUserTown", "");

    const [userPhoneRepeat, setUserPhoneRepeat] = useLocalStorage(
        "inputShoppingCartUserPhoneRepeat",
        "",
    );

    // Siempre envío GLS
    const [userSendType] = useState("2");

    const userNameInput = useRef();
    const userEmailInput = useRef();
    const userPhoneInput = useRef();
    const userAddressInput = useRef();
    const userPostalCodeInput = useRef();
    const userTownInput = useRef();
    const userProvinceInput = useRef();
    const userLastNameInput = useRef();
    const userEmailRepeatInput = useRef();
    const userPhoneRepeatInput = useRef();

    // Si tenemos datos del perfil del usuario registrado, los usamos
    useEffect(() => {
        if (userContactData) {
            userContactData.name && setUserName(userContactData.name);
            userContactData.lastName && setUserLastName(userContactData.lastName);
            userContactData.email && setUserEmail(userContactData.email);
            userContactData.email && setUserEmailRepeat(userContactData.email);
            userContactData.phone && setUserPhone(userContactData.phone);
            userContactData.phone && setUserPhoneRepeat(userContactData.phone);
            userContactData.postalCode && setUserPostalCode(userContactData.postalCode);
            userContactData.town && setUserTown(userContactData.town);
            userContactData.address && setUserAddress(userContactData.address);
        }
    }, [
        userContactData,
        setUserEmail,
        setUserPhone,
        setUserPhoneRepeat,
        setUserLastName,
        setUserName,
        setUserAddress,
        setUserTown,
        setUserEmailRepeat,
        setUserPostalCode,
    ]);

    // Reseteamos datos del formulario
    const resetCart = () => {
        if (window.confirm("¿Estás seguro en cancelar tu compra?")) {
            setUserName("");
            setUserLastName("");
            setUserEmail("");
            setUserEmailRepeat("");
            setUserPhone("");
            setUserPhoneRepeat("");
            setUserAddress("");
            handleCheckout(false);
            setReadyToPayment(false);
            setDiscountCode("");
            setDiscount(null);
        }
    };

    // Reseteamos carrito
    const emptyCart = () => {
        if (window.confirm("¿Estás seguro en vaciar la cesta?")) {
            clearCart();
            setDiscountCode("");
            setDiscount(null);
        }
    };

    // Valida los datos del formulario
    const validateData = () => {
        if (!userName || userName.trim() === "") {
            window.alert('El campo "nombre" no puede estar vacío.');
            userNameInput.current.focus();
            return false;
        }

        if (!userLastName || userLastName.trim() === "") {
            window.alert('El campo "apellidos" no puede estar vacío.');
            userLastNameInput.current.focus();
            return false;
        }

        if (!userEmail || userEmail.trim() === "") {
            window.alert('El campo "correo electrónico" no puede estar vacío.');
            userEmailInput.current.focus();
            return false;
        }

        if (!userEmailRepeat || userEmailRepeat.trim() === "") {
            window.alert('El campo "correo electrónico" no puede estar vacío.');
            userEmailRepeatInput.current.focus();
            return false;
        }

        if (userEmailRepeat !== userEmail) {
            window.alert('El campo "correo electrónico" y su repetición no son iguales.');
            userEmailRepeatInput.current.focus();
            return false;
        }

        const emailPattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        );

        if (!emailPattern.test(userEmail)) {
            window.alert('El campo "correo electrónico" no tiene un formato correcto.');
            userEmailInput.current.focus();
            return false;
        }

        if (!userPhone || userPhone.trim() === "") {
            window.alert('El campo "teléfono de contacto" no puede estar vacío.');
            userPhoneInput.current.focus();
            return false;
        }

        if (!userPhoneRepeat || userPhoneRepeat.trim() === "") {
            window.alert('El campo "teléfono de contacto" no puede estar vacío.');
            userPhoneRepeatInput.current.focus();
            return false;
        }

        const phonePattern = new RegExp(/^[0-9\b]+$/);
        if (!phonePattern.test(userPhone)) {
            window.alert('El campo "teléfono de contacto" no tiene un formato correcto.');
            userPhoneInput.current.focus();
            return false;
        }

        if (!phonePattern.test(userPhoneRepeat)) {
            window.alert('El campo "teléfono de contacto" no tiene un formato correcto.');
            userPhoneRepeatInput.current.focus();
            return false;
        }

        if (userPhone !== userPhoneRepeat) {
            window.alert('El campo "teléfono de contacto" y su repetición no son iguales.');
            userPhoneRepeatInput.current.focus();
            return false;
        }

        if (!userAddress || userAddress.trim() === "") {
            window.alert('El campo "dirección de envío" no puede estar vacío.');
            userAddressInput.current.focus();
            return false;
        }

        if (!userPostalCode || userPostalCode.trim() === "") {
            window.alert('El campo "código postal" no puede estar vacío.');
            userPostalCodeInput.current.focus();
            return false;
        }

        if (!userTown || userTown.trim() === "") {
            window.alert('El campo "municipio" no puede estar vacío.');
            userTownInput.current.focus();
            return false;
        }

        if (!userProvince || userProvince.trim() === "") {
            window.alert('El campo "provincia" no puede estar vacío.');
            userProvinceInput.current.focus();
            return false;
        }

        return true;
    };

    // Verifica que el pago sea correcto
    const verifyPayment = async data => {
        try {
            setBlocking(true);
            const endpoint = "/.netlify/functions/paymentcheck";

            axios
                .post(
                    endpoint,
                    JSON.stringify({
                        cartData: cartItems,
                        orderId: data.id,
                        shipmentInfo: "",
                        address: userAddress,
                        postalCode: userPostalCode,
                        town: userTown,
                        province: userProvince,
                        name: userName,
                        lastName: userLastName,
                        phone: userPhone,
                        email: userEmail,
                        paymentGateway: "paypal",
                        shipmentType: userSendType || "",
                        discount: discount?.slug,
                    }),
                )
                .then(r => {
                    if (r.status === 200) {
                        // Compra correcta y validada
                        // Mostramos mensaje de todo ok y borramos la cesta

                        // Construimos array de items
                        const items =
                            cartItems &&
                            cartItems.map(i => {
                                return {
                                    item_id: i.sku,
                                    item_name: i.name,
                                    item_category: i.type,
                                    price: i.price,
                                    currency: "EUR",
                                    quantity: i.quantity,
                                };
                            });

                        // En caso de añadir al carrito
                        (async () => {
                            trackFbPurchase({
                                currency: "EUR",
                                value: total,
                            });
                        })();

                        // Enviamos mensaje a Firebase

                        firebase.analytics().logEvent("purchase", {
                            currency: "EUR",
                            transaction_id: data.id,
                            value: total,
                            shipping: shippingTotal,
                            items,
                        });

                        playPurchaseSuccess();

                        setExecutedPayment({
                            success: true,
                            type: "success",
                            message: `¡Enhorabuena! El proceso de pago se ha completado con éxito. En breve recibirás un correo electrónico con todos los detalles. Código de transación ${data.id}.`,
                        });

                        // Limpiamos todo lo asociado a la cesta y el pago
                        clearCart();

                        // Qutamos descuentos, si hay

                        setDiscountCode("");
                        setDiscount(null);
                    } else {
                        setReadyToPayment(false);
                        // Mostramos que ha habido un error en el pago
                    }
                })
                .catch(e => {
                    playPurchaseError();
                    setReadyToPayment(false);
                    handleCheckout(false);
                    setExecutedPayment({
                        success: false,
                        type: "danger",
                        message: `Ha habido un error en el proceso de pago.`,
                    });
                    // Mostramos que ha habido un error en el pago
                })
                .finally(() => {
                    setBlocking(false);
                });
        } catch (err) {
            playPurchaseError();
            setReadyToPayment(false);
            handleCheckout(false);
            return false;
        }
    };

    // Aplica un cupón de descuento, si procede
    const onSubmitDiscount = async e => {
        e.preventDefault();

        setDiscountLoading(true);

        try {
            if (!userEmail || userEmail.trim() === "") {
                toast.warning("Debe rellenar los datos del envío antes de aplicar un descuento.", {
                    position: "bottom-center",
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 5000,
                });
                return;
            }

            const json = await axios.post(
                "/.netlify/functions/apply-discount",
                JSON.stringify({
                    email: userEmail,
                    slug: discountCode,
                    items: cartItems?.map(i => i.id),
                }),
            );

            const discount = json?.data?.discount;

            if (discount) {
                // Miramos si podemos aplicar el descuento
                if (discount?.types?.length > 0) {
                    // miramos si hay restricciones de types
                    if (
                        !cartItems
                            ?.map(i => i.type)
                            .some(element => {
                                return discount?.types?.indexOf(element) !== -1;
                            })
                    ) {
                        toast.warning(
                            "El descuento no puede aplicarse a los productos seleccionados.",
                            {
                                position: "bottom-center",
                                closeOnClick: true,
                                pauseOnHover: true,
                                autoClose: 5000,
                            },
                        );

                        return;
                    }
                }

                setDiscount(discount);
                playAddToBasket();

                // Mostramos toast
                toast.info("¡Descuento aplicado con éxito!", {
                    position: "bottom-center",
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 5000,
                });
                setDiscountCode("");
            } else {
                // No se ha encontrado el código válido
                toast.warning("El descuento no existe o no es aplicable.", {
                    position: "bottom-center",
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 5000,
                });
            }
        } catch (err) {
            console.log(err?.message);
            toast.warning("El descuento no existe o no es aplicable.", {
                position: "bottom-center",
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 5000,
            });
        }

        setDiscountLoading(false);
    };

    return (
        <>
            <div className={`${props.className}`}>
                <div className="shopping-cart">
                    <h3 className="font-montserrat text-sm font-bold text-gray-500 mb-5 pb-1 uppercase border-b-2 inline-block px-2">
                        Resumen del pedido
                    </h3>

                    <table className="hidden lg:table shopping-cart-table">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Precio</th>
                                <th>Cantidad</th>
                                <th>Sub-Total</th>
                                <th>Modificar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartItems &&
                                cartItems.map(item => {
                                    return (
                                        <CartItem
                                            key={item.id}
                                            item={item}
                                            setDiscount={setDiscount}
                                            playAddToBasket={playAddToBasket}
                                            playRemoveFromBasket={playRemoveFromBasket}
                                        />
                                    );
                                })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="4" className="text-right">
                                    Sub-total:
                                </th>
                                <th colSpan="1">{Number(total).toFixed(2)} €</th>
                            </tr>
                            <tr className="white">
                                <th colSpan="4" className="text-right">
                                    Gastos envío ({sendableUnits} uds.):
                                </th>
                                <th colSpan="1">{Number(shippingTotal).toFixed(2)} €</th>
                            </tr>
                            <tr>
                                <th colSpan="3" className="text-left font-normal px-5">
                                    {discount && (
                                        <span>
                                            {discount.title} [{discount.slug}]
                                        </span>
                                    )}
                                </th>
                                <th colSpan="1" className="text-right">
                                    Descuento:
                                </th>
                                <th colSpan="1">
                                    {discount && discount.price && (
                                        <>{Number(discount.price || 0).toFixed(2)}</>
                                    )}
                                    {discount && discount.percent && (
                                        <>{Number(total * discount.percent).toFixed(2)}</>
                                    )}
                                    {!discount ||
                                        (!discount.price && !discount.percent && <>0.00</>)}
                                    €
                                </th>
                            </tr>
                            <tr className="white">
                                <th colSpan="4" className="text-right">
                                    Total:
                                </th>
                                <th colSpan="1">
                                    {(
                                        Number(total) +
                                        Number(shippingTotal) -
                                        Number(
                                            discount?.price ||
                                                (discount?.percent ? discount.percent * total : 0),
                                        )
                                    ).toFixed(2)}{" "}
                                    €
                                </th>
                            </tr>
                        </tfoot>
                    </table>

                    {/** Mobile first */}
                    <table className="lg:hidden shopping-cart-table">
                        <thead>
                            <tr>
                                <th>Producto</th>
                            </tr>
                        </thead>

                        <tbody>
                            {cartItems &&
                                cartItems.map(item => {
                                    return (
                                        <CartItem
                                            key={item.id}
                                            item={item}
                                            playAddToBasket={playAddToBasket}
                                            playRemoveFromBasket={playRemoveFromBasket}
                                        />
                                    );
                                })}
                        </tbody>

                        <tfoot>
                            <tr>
                                <th colSpan="1" className="text-center">
                                    Sub-total: {Number(total).toFixed(2)} €
                                </th>
                            </tr>
                            <tr className="white">
                                <th colSpan="1" className="text-center">
                                    Gastos envío: {Number(shippingTotal).toFixed(2)} €
                                </th>
                            </tr>
                            <tr>
                                <th colSpan="1" className={`text-center`}>
                                    Descuento:{" "}
                                    {discount && discount.price && (
                                        <>{Number(discount.price || 0).toFixed(2)}</>
                                    )}
                                    {discount && discount.percent && (
                                        <>{Number(total * discount.percent).toFixed(2)}</>
                                    )}
                                    {!discount ||
                                        (!discount.price && !discount.percent && <>0.00</>)}
                                    €
                                </th>
                            </tr>
                            <tr className="white">
                                <th colSpan="1" className="text-center">
                                    Total:{" "}
                                    {(
                                        Number(total) +
                                        Number(shippingTotal) -
                                        Number(
                                            discount?.price ||
                                                (discount?.percent ? discount.percent * total : 0),
                                        )
                                    ).toFixed(2)}{" "}
                                    €
                                </th>
                            </tr>
                        </tfoot>
                    </table>

                    {/* Discount codes */}
                    {process.env.GATSBY_DISCOUNTS_ENABLED === "true" && (
                        <div className="my-10">
                            <h3 className="font-montserrat text-sm font-bold text-gray-500 mb-5 pb-1 uppercase border-b-2 inline-block px-2">
                                Cupones de descuento
                            </h3>

                            <form
                                id="discount-form"
                                className="grid grid-cols-none md:grid-cols-2 gap-5"
                                onSubmit={onSubmitDiscount}
                            >
                                <input
                                    type="text"
                                    disabled={discount}
                                    value={discountCode}
                                    required={true}
                                    onChange={e => setDiscountCode(e.target.value)}
                                    placeholder="CÓDIGO-CUPÓN-DESCUENTO"
                                    className="input col-span-2 md:col-span-1"
                                />

                                {!discount && (
                                    <button
                                        disabled={discount}
                                        type="submit"
                                        className={`space-x-5 items-center duration-300 col-span-2 md:col-span-1 button-alt flex justify-center ${
                                            discount
                                                ? "cursor-not-allowed opacity-25"
                                                : "opacity-100"
                                        }`}
                                    >
                                        {discountLoading && (
                                            <AiOutlineLoading className="duration-300 animate-spin" />
                                        )}
                                        <span>Aplicar código descuento</span>
                                    </button>
                                )}

                                {discount && (
                                    <button
                                        disabled={!discount}
                                        onClick={() => {
                                            setDiscount(null);
                                            setDiscountCode("");
                                            playRemoveFromBasket();

                                            toast.warning("El descuento se ha eliminado.", {
                                                position: "bottom-center",
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                autoClose: 5000,
                                            });
                                        }}
                                        className={`duration-300 col-span-2 md:col-span-1 button flex justify-center ${
                                            !discount
                                                ? "cursor-not-allowed opacity-25"
                                                : "opacity-100"
                                        }`}
                                    >
                                        Quitar código de descuento
                                    </button>
                                )}
                            </form>
                        </div>
                    )}

                    <div className="text-right text-xs mt-3 italic font-montserrat text-gray-500">
                        <p>* I.V.A. incluido en todos los precios</p>
                        <p>** Gastos de envío variables según número de artículos enviables.</p>
                        <p>*** Los códigos de descuento no son acumulables.</p>
                    </div>

                    {/** Formualrio datos del usuario */}
                    <div className="mt-10 border-t border-gray-200 py-10 border-b">
                        <h3 className="font-montserrat text-sm font-bold text-gray-500 mb-5 pb-1 uppercase border-b-2 inline-block px-2">
                            Datos del envío
                        </h3>
                        <div className="grid grid-cols-none md:grid-cols-2 gap-5">
                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userNameInput}
                                className="input col-span-2 md:col-span-1"
                                placeholder="Nombre"
                                type="text"
                                id="userName"
                                value={userName}
                                onChange={e => {
                                    setUserName(e.target.value);
                                }}
                            />

                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userLastNameInput}
                                className="input col-span-2 md:col-span-1"
                                placeholder="Apellidos"
                                type="text"
                                id="userLastName"
                                value={userLastName}
                                onChange={e => {
                                    setUserLastName(e.target.value);
                                }}
                            />

                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userEmailInput}
                                className="input col-span-2 md:col-span-1"
                                placeholder="Correo electrónico"
                                type="email"
                                id="userEmail"
                                value={userEmail}
                                onChange={e => {
                                    setUserEmail(e.target.value);
                                }}
                            />

                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userEmailRepeatInput}
                                className="input col-span-2 md:col-span-1"
                                placeholder="Correo electrónico (repetir)"
                                type="email"
                                id="userEmailRepeat"
                                value={userEmailRepeat}
                                onChange={e => {
                                    setUserEmailRepeat(e.target.value);
                                }}
                            />

                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userPhoneInput}
                                className="input col-span-2 md:col-span-1"
                                placeholder="Teléfono de contacto"
                                type="tel"
                                id="userPhone"
                                value={userPhone}
                                onChange={e => {
                                    setUserPhone(e.target.value);
                                }}
                            />

                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userPhoneRepeatInput}
                                className="input col-span-2 md:col-span-1"
                                placeholder="Teléfono de contacto (repetir)"
                                type="tel"
                                id="userPhoneRepeat"
                                value={userPhoneRepeat}
                                onChange={e => {
                                    setUserPhoneRepeat(e.target.value);
                                }}
                            />

                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userAddressInput}
                                className="input col-span-2"
                                placeholder="Dirección de envío"
                                type="text"
                                id="userAddress"
                                value={userAddress}
                                onChange={e => {
                                    setUserAddress(e.target.value);
                                }}
                            />

                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userPostalCodeInput}
                                className="input col-span-2 md:col-span-1"
                                placeholder="Código Postal"
                                type="number"
                                id="userPostalCode"
                                value={userPostalCode}
                                onChange={e => {
                                    setUserPostalCode(e.target.value);
                                }}
                            />

                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userTownInput}
                                className="input col-span-2 md:col-span-1"
                                placeholder="Municipio"
                                type="text"
                                id="userTown"
                                value={userTown}
                                onChange={e => {
                                    setUserTown(e.target.value);
                                }}
                            />

                            <input
                                disabled={checkout || cartItems?.length === 0}
                                ref={userProvinceInput}
                                className="input col-span-2"
                                placeholder="Provincia"
                                type="text"
                                id="userProvince"
                                value={userProvince}
                                onChange={e => {
                                    setUserProvince(e.target.value);
                                }}
                            />

                            {checkout ? (
                                <button
                                    className="button-alt col-span-2 md:col-span-1"
                                    onClick={() => {
                                        resetCart();
                                    }}
                                >
                                    <MdRemoveShoppingCart className="w-5 h-5 mr-5 hidden md:inline" />
                                    Cancelar pago
                                </button>
                            ) : (
                                <button
                                    disabled={checkout || cartItems?.length === 0}
                                    className={`button-alt col-span-2 md:col-span-1 ${
                                        checkout || cartItems?.length === 0
                                            ? "cursor-not-allowed opacity-25"
                                            : "opacity-100"
                                    }`}
                                    onClick={() => {
                                        emptyCart();
                                    }}
                                >
                                    <MdRemoveShoppingCart className="w-5 h-5 mr-5 hidden md:inline" />
                                    Vaciar cesta
                                </button>
                            )}

                            <button
                                disabled={checkout || cartItems?.length === 0}
                                className={`duration-300 col-span-2 md:col-span-1 button flex justify-center ${
                                    checkout || cartItems?.length === 0
                                        ? "cursor-not-allowed opacity-25"
                                        : "opacity-100"
                                }`}
                                onClick={() => {
                                    const isValidData = validateData();
                                    if (isValidData) {
                                        handleCheckout(true);
                                        setReadyToPayment(true);
                                    }
                                }}
                            >
                                <MdPayment className="w-5 h-5 mr-5 hidden md:inline" />
                                Realizar pago
                            </button>
                        </div>
                    </div>

                    {/** Pagos */}
                    {readyToPayment && (
                        <div className="border-b py-10">
                            <h3 className="font-montserrat text-sm font-bold text-gray-500 mb-5 pb-1 uppercase border-b-2 inline-block px-2">
                                Métodos de pago
                            </h3>

                            <PaypalPayment
                                style={{
                                    layout: "vertical",
                                    shape: "rect",
                                    color: "gold",
                                    label: "pay",
                                    tagline: false,
                                    size: "responsive",
                                }}
                                //className="w-full"
                                items={cartItems}
                                discount={discount}
                                total={(
                                    Number(total) +
                                    Number(shippingTotal) -
                                    Number(
                                        discount?.price || discount?.percent
                                            ? discount?.percent * total
                                            : 0 || 0,
                                    )
                                ).toFixed(2)}
                                shipping={Number(shippingTotal).toFixed(2)}
                                subtotal={total}
                                currency={"EUR"}
                                functions={{
                                    onStart: () => {
                                        (async () => {
                                            trackFbInitiateCheckout({
                                                currency: "EUR",
                                                value: total,
                                            });
                                        })();
                                    },
                                    onError: e => {
                                        playPurchaseError();
                                        setBlocking(false);
                                        setReadyToPayment(false);
                                        handleCheckout(false);
                                        setExecutedPayment({
                                            success: false,
                                            type: "danger",
                                            message: `Ha habido un error en el proceso. Error: ${e}`,
                                        });
                                    },
                                    onCancel: () => {
                                        playPurchaseError();
                                        setBlocking(false);
                                        setReadyToPayment(false);
                                        handleCheckout(false);
                                        setExecutedPayment({
                                            success: false,
                                            type: "warning",
                                            message: "El proceso de pago se ha cancelado.",
                                        });
                                    },
                                    onSuccess: async e => {
                                        // Verificamos pago en el WS
                                        await verifyPayment(e);
                                    },
                                    setProcessing: e => {
                                        //setBlocking(e);
                                    },
                                }}
                            />

                            <div className="mt-0 grid grid-cols-1 md:grid-cols-2 gap-10">
                                {/** Paypal */}
                                <div className="duration-300 "></div>
                                {/** Stripe */}
                                <div className="duration-300"></div>
                            </div>
                        </div>
                    )}

                    {/** Mensaje al usuario */}
                    {executedPayment && (
                        <div className="pt-10 border-t duration-300">
                            <CustomAlert type={executedPayment.type}>
                                {executedPayment.message}
                            </CustomAlert>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const CartItem = ({ item, playAddToBasket, playRemoveFromBasket, setDiscount }) => {
    const { removeProduct, addProduct } = useContext(CartContext);
    return (
        <>
            <tr className="hidden lg:table-row table-item">
                <td>
                    {item.name}
                    <ul className="text-xs">
                        <li className="text-gray-500">- SKU: {item.sku}</li>
                    </ul>
                </td>
                <td className="text-center">{Number(item.price)} €</td>
                <td className="text-center">{Number(item.quantity)}</td>
                <td className="text-center">{Number(item.price * item.quantity).toFixed(2)} €</td>
                <td className="text-center">
                    {item.type !== "discount" && (
                        <button
                            className={`mr-3 `}
                            // disabled={!item.cumulative}
                            title="Incrementar unidades"
                            onClick={() => {
                                playAddToBasket();
                                addProduct(item);

                                toast.info("Se han incrementado las unidades del producto.", {
                                    position: "bottom-center",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    autoClose: 5000,
                                });

                                firebase.analytics().logEvent("add_to_cart", {
                                    item_id: item.sku,
                                    item_name: item.name,
                                    item_category: item.type,
                                    price: item.price,
                                    currency: "EUR",
                                    quantity: 1,
                                });
                            }}
                        >
                            <IoIosAddCircleOutline
                                className="w-5 h-5 text-green-500"
                                style={{ verticalAlign: "middle" }}
                            />
                        </button>
                    )}

                    <button
                        title="Decrementar unidades"
                        onClick={() => {
                            if (item.quantity === 1) {
                                if (!window.confirm("¿Estás seguro en eliminar este producto?")) {
                                    return;
                                }
                            }

                            playRemoveFromBasket();
                            removeProduct(item);

                            toast.info("Se han decremantado las unidades del producto.", {
                                position: "bottom-center",
                                closeOnClick: true,
                                pauseOnHover: true,
                                autoClose: 5000,
                            });

                            firebase.analytics().logEvent("remove_from_cart", {
                                item_id: item.sku,
                                item_name: item.name,
                                item_category: item.type,
                                price: item.price,
                                currency: "EUR",
                                quantity: 1,
                            });
                        }}
                    >
                        <IoIosRemoveCircleOutline
                            className="w-5 h-5 text-red-500"
                            style={{ verticalAlign: "middle" }}
                        />
                    </button>
                </td>
            </tr>

            <tr className="lg:hidden table-item">
                <td>
                    {item.name}
                    <ul className="text-xs">
                        <li className="text-gray-500">- SKU: {item.sku}</li>
                        <li className="text-gray-500">- Cantidad: {item.quantity}</li>
                        <li className="text-gray-500">
                            - Importe: {Number(item.price).toFixed(2)}
                        </li>
                        <li className="text-gray-600 font-bold">
                            - Sub-total: {Number(item.quantity * item.price).toFixed(2)} €
                        </li>
                        <li className="mt-2">
                            <button
                                className=""
                                title="Incrementar unidades"
                                onClick={() => {
                                    playAddToBasket();
                                    addProduct(item);

                                    toast.info("Se han incrementado las unidades del producto.", {
                                        position: "bottom-center",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        autoClose: 5000,
                                    });

                                    firebase.analytics().logEvent("add_to_cart", {
                                        item_id: item.sku,
                                        item_name: item.name,
                                        item_category: item.type,
                                        price: item.price,
                                        currency: "EUR",
                                        quantity: 1,
                                    });
                                }}
                            >
                                <IoIosAddCircleOutline
                                    className="w-8 h-8 text-green-500"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </button>

                            <button
                                className=""
                                title="Decrementar unidades"
                                onClick={() => {
                                    if (item.quantity === 1) {
                                        if (
                                            !window.confirm(
                                                "¿Estás seguro en eliminar este producto?",
                                            )
                                        ) {
                                            return;
                                        }
                                    }

                                    playRemoveFromBasket();
                                    removeProduct(item);

                                    toast.info("Se han decremantado las unidades del producto.", {
                                        position: "bottom-center",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        autoClose: 5000,
                                    });

                                    firebase.analytics().logEvent("remove_from_cart", {
                                        item_id: item.sku,
                                        item_name: item.name,
                                        item_category: item.type,
                                        price: item.price,
                                        currency: "EUR",
                                        quantity: 1,
                                    });
                                }}
                            >
                                <IoIosRemoveCircleOutline
                                    className="w-8 h-8 text-red-500"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </button>
                        </li>
                    </ul>
                </td>
            </tr>
        </>
    );
};

export default ShoppingCart;
